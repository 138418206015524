
$black: black;
$black2: #010101;

$blue1: #063a83;
$blue2: #88aae0;
$blue3: darkblue;
$blue4: #d5e0f2;
$blue5: #00558a;
$blue6: #126be8;
$blue7: #b3c3dd; // master data dropdown hover color
$blue8: #014e8c; // shipment notes blue gradient
$blue9: #0e3283; // datepicker form border
$blue10: #0d6b79; // global search results(quotes)
$blue11: #4867a9; // blue-gray borders on Clipboard header

$gray0: #ebeef1;
$gray: grey;
$gray2: darkgray;
$gray3: #544a4a;
$gray4: #c2cce0; // used for dividers in record creation, and scrollbar thumbs for white backgrounds
$gray5: #6d6c6c;
$gray6: #c1c1c1;
$gray7: #ffffff;
$gray8: lightgray;
$gray9: #ced6e6;
$gray10: #343434;
$gray11: #d2d3d4;
$gray12: #545454; // used for read-only select text color
$gray13: #dfdfdf; // used for app header seperator line
$gray14: #d2d2d2;
$gray15: #939393; // used in datePicker
$gray16: #b7c2cf; // used for total cost outline (reporting- summary reports)
$gray17: #d9dee4; //this is the scrollbar thumb color for gray colored backgrounds
$white: white;

$green1: #63A12C; // inner quote record tab color
$green2: #007D34; // outer general ref record tab color
$green3: #147E3A; // for save to dashboard view icons (binoculars)
$green4: #009c4b; // shipment notes green gradient
$green5: #00a44d; //create record/user profile/master data saved buttons marked dirty
$green6: #12723a; // used in role detail permissions page

$teal1: #006B7A; // outer shipper record tab color
$teal2: #00558A; //outer consignee record tab color

$purple1: #231D90; // outer product list record tab color

$magenta1: #870671; // outer carrier ref record tab color
$magenta2: #920457; // outer invoicing record tab color

$red1: #ff004a;
$red2: #A8081E; // outer attached files record tab color
$red3: #c41d3e;
$red4: #db1028;
$red5: #a00016; // used in the site inaccessible modal
$red6: #f1354b;

$orange1: #D26906; // outer summary record tab color
$yellow1: #f4cd62;  // fill color for favorite star icon
$yellow2: #bc9047;  //outline for favorite star icon
$yellow3: #eabd43; // color for warning icon (as used when blocking parts of record creation)

$appHeaderHeight: 70px;

$odysseyGreen: #42AD98;
$odysseyBlue: #063A83;
$odysseyGrey: #E8E9EB;
$odysseyDarkGrey: #1B2537;
