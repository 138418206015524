/* You can add global styles to this file, and also import other style files */
// @import url('https://fonts.googleapis.com/css?family=Lato');
@use './styles-shared.scss' as *;
@use 'ngx-toastr/toastr' as *;
@import url('https://fonts.googleapis.com/css?family=Open+Sans');
body {
    margin: 0;
}
* {
    font-family: "Open Sans", sans-serif;
}
// h1, h2, p, div, body, button {
//     font-family: "Open Sans", sans-serif;
//  }

.clickable {
    cursor: pointer;
}

.header1 {
    font-size: 4em;
    color: $blue1;
}

.header2 {
    font-size: 2em;
    color: $blue1;
}
.header3 {
    font-size: 1.5em;
    color: $blue1;
}
.breadcrumbs {
    font-size: 1em;
}
.homePanel {
    display: flex;
    flex-flow: column nowrap;
    padding-top: 1em;
    background-color: $white;
    /* justify-content: center;
    align-items: center; */
    /* WALDO - this is causing scrollbars
    height: 100%; */
}

.mainBody {
    flex: 1 0;
    /* padding-left: 1em; */
}
.sectionPanel {
    display: flex;
}

.sectionHeader {
    font-size: 1em;
}

table thead {
    // text-align: left;
    // background-color: $blue2;
    // border-bottom: 1px solid $gray;
    // border-right: 1px solid $gray;
    // border-left: 1px solid $gray;
}

tbody tr:nth-child(even) {
    // background-color: $blue4;
}
.highlighted {
    background: yellow;
}
.padLeft {
     padding-left: 2vw;
}
.padRight {
    padding-right: 2vw;
}
.button {
    background: $gray0;
    border: 2px solid black;
    border-radius: 6px;
    margin: 0 0.25em;
    padding: 0.5em;
    text-transform: capitalize;
    font-weight: bold;
    letter-spacing: 0.5px;
    cursor: pointer;
}
.btn-outline {
    @extend .button;
    background: $white;
    border: 2px solid $gray4;
    color: $blue1;
    text-transform: uppercase;

    &:hover {
        border: solid 2.5px $blue9;
        // height: calc(4px - )
    }
    &.active {
        background: $white;
        // border-color: $blue1;
        color: $blue1;
    }

    &.inactive {
        pointer-events: none;
        opacity: 0.5;
    }
}
.btn-filled {
    @extend .button;
    background: $blue9;
    border: 2px solid $blue9;
    color: $white;
    text-transform: uppercase;

    &:hover {
        background: $white;
        border: 2.5px solid $blue9;
        color: $blue9;
    }

    &.active {
        background: $green5;
        border-color: $green5;
        color: $white;
    }

    &.inactive {
        pointer-events: none;
        opacity: 0.5;
    }
}
.btn-filled-red {
    @extend .button;
    background: $red1;
    border: 2px solid $red1;
    color: $white;
    text-transform: uppercase;

    &:hover {
        background: $white;
        border: 2.5px solid $blue9;
        color: $blue9;
    }

    &.active {
        background: $green5;
        border-color: $green5;
        color: $white;
    }

    &.inactive {
        pointer-events: none;
        opacity: 0.5;
    }
}
.btn-filled-green {
    @extend .button;
    background: $green5;
    border: 2px solid $green5;
    color: $white;
    text-transform: uppercase;

    &:hover {
        background: $white;
        border: 2.5px solid $blue9;
        color: $blue9;
    }

    &.active {
        background: $green5;
        border-color: $green5;
        color: $white;
    }

    &.inactive {
        pointer-events: none;
        opacity: 0.5;
    }
}
.btn-background {
    @extend .button;
    background: $blue1;
    border: 2px solid $white;
    color: $white;
    text-transform: uppercase;
    // font-weight: 600;

    &:hover {
        background: $white;
        color: $blue1;
        border: 2.5px solid $white;
    }

    &.inactive {
        pointer-events: none;
        opacity: 0.5;
    }
}
.btn-icon {
    font-size: 0.9em;
}
.actionText {
    font-weight: bolder;
    cursor: pointer;
}
.align-l {
    text-align: left;
}
.align-c {
    text-align: center;
}
.align-r {
    text-align: right;
}
.btn-hidden-IE {
    opacity: 0;
}
input {
    background-color: $gray0;
    text-indent: 0.25em;
    font-size: 1em;
    height: 2em;
    border-radius: 6px;
    border: none;
    text-indent: 8px;
    font-weight: bold;
}
input:-webkit-autofill,
input:-webkit-autofill:hover,
input:-webkit-autofill:focus
textarea:-webkit-autofill,
textarea:-webkit-autofill:hover
textarea:-webkit-autofill:focus,
select:-webkit-autofill,
select:-webkit-autofill:hover,
select:-webkit-autofill:focus {
    -webkit-box-shadow: 0 0 0 30px $gray0 inset;
    // -webkit-text-fill-color: white !important;
}
.clickableCaret {
z-index: 1;
pointer-events: none;
}
.clickableCaretPositioning {
    position: relative;
    right: .5em;
}
.clickableRateCaretPositioning {
    position: relative;
    right: 1em;
}
.clickableCaretExtension {
    background-color: $gray0;
    border-radius: 4px;
}

select {
    background-color: $gray0;
    font-size: 1em;
    height: 2em;
    text-indent: 8px;
    border-radius: 6px;
    border: none;
    font-weight: bold;
    // z-index: 1;
}
option {
    background: white;
    color: $blue3;
    text-indent: 8px;
    font-weight: bold;
}
.inputSearchBox {
    background-color: $gray0;
    color: $blue5;
}
.blue {
    color: $blue1;
}
.required {
    color: $red6;
    font-size: 1.2em;
}
.recordCreationWizardSection {
    background-color: $white;
    margin-left: 2vw;
    margin-right: 2vw;
}
hr {
    color: $gray4;
    border: 0.5px solid $gray4;
}
.recordCreationFormRadio {
    color: $blue3;
    padding-right: 0.5em;
}
.recordCreationFormRadioLabel {
    margin-right: 2em;
    font-weight: bold;
}
.bottomBorderShadow {   // CSS for any element (usually result container rows) that need to have a bottom shadow
    // box-shadow: 0 5px 6px -6px gray;
    box-shadow: 0px 1px 3px 0 rgba(0, 0, 0, 0.15);
}
// ======================== scrollbar scss ===================================
.scrollbar::-webkit-scrollbar { // NOTE(Hassan) apply class 'scrollbar' to element with overflow-y: auto, only works for chrome
  width: 10px;
  border-radius: 6px;
  background-color: $gray4;
}
.scrollbar::-webkit-scrollbar-thumb {
  background-color: $white;
  border-radius: 6px;
  box-shadow: 0 0 2px $gray4;
  border: 1.5px solid $gray4;
}
.scrollbarGray::-webkit-scrollbar { // NOTE(Hassan) apply class 'scrollbar' to element with overflow-y: auto, only works for chrome
  width: 10px;
  border-radius: 6px;
  background-color: $gray17;
}
.scrollbarGray::-webkit-scrollbar-thumb {
  background-color: $white;
  border-radius: 6px;
  box-shadow: 0 0 2px $gray4;
  border: 1.5px solid $gray4;
}

// ======================== icon colors ===================================
.current .currentOuterIcon {
    color: $white;
}
.quoteOuterIcon {
    color: $green1;
}
.quoteInnerIcon {
    color: $white;
}
.current .quoteInnerIcon {
    color: $green1;
}
.genRefOuterIcon {
    color: $green2;
}
.genRefInnerIcon {
    color: $white;
}
.current .genRefInnerIcon {
    color: $green2;
}
.shipperOuterIcon {
    color: $teal1;
}
.shipperInnerIcon {
    color: $white;
}
.current .shipperInnerIcon {
    color: $teal1;
}
.consigneeOuterIcon {
    color: $teal2;
}
.consigneeInnerIcon {
    color: $white;
}
.current .consigneeInnerIcon {
    color: $teal2;
}
.productListOuterIcon {
    color: $purple1;
}
.productListInnerIcon {
    color: $white;
}
.current .productListInnerIcon {
    color: $purple1;
}
.carrierRefOuterIcon {
    color: $magenta1;
}
.carrierRefInnerIcon {
    color: $white;
}
.current .carrierRefInnerIcon {
    color: $magenta1;
}
.invoicingOuterIcon {
    color: $magenta2;
}
.invoicingInnerIcon {
    color: $white;
}
.current .invoicingInnerIcon {
    color: $magenta2;
}
.attachedFilesOuterIcon {
    color: $red2;
}
.attachedFilesInnerIcon {
    color: $white;
}
.current .attachedFilesInnerIcon {
    color: $red2;
}
.summaryOuterIcon {
    color: $orange1;
}
.summaryInnerIcon {
    color: $white;
}
.current .summaryInnerIcon {
    color: $orange1;
}

input:focus, textarea:focus {
    outline: none;
}
select {
    -webkit-appearance: none;
    -moz-appearance: none;
}
::-ms-clear {
    display: none;
}
.uncheckedBox {
    color: $gray4;
}
.checkedBox {
    color: $blue9;
}
.ellipsis {
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
}
// IE Specific CSS
@media all and (-ms-high-contrast: none), (-ms-high-contrast: active) {
    /* IE10+ CSS styles go here */
   select::-ms-expand {
       display: none;
   }
}

.tooltip {
    position: relative;
    display: inline-block;
}

.tooltip .tooltiptext {
    visibility: hidden;
    width: 120px;
    background-color: black;
    color: #fff;
    text-align: center;
    border-radius: 6px;
    padding: 5px 0;
    font-size: small;

    /* Position the tooltip */
    position: absolute;
    z-index: 1;
}

.tooltip:hover .tooltiptext {
    visibility: visible;
}

.customerGeneralOuterIcon {
    color: $green1;
}
.current .customerGeneralOuterIcon {
    color: $white;
}
.customerGeneralInnerIcon {
    color: $white;
}
.current .customerGeneralInnerIcon {
    color: $green1;
}

.customerApiOuterIcon {
    color: $blue5;
}
.current .customerApiOuterIcon {
    color: $white;
}
.customerApiInnerIcon {
    color: $white;
}
.current .customerApiInnerIcon {
    color: $blue5;
}

.customerOltOuterIcon {
    color: $magenta1;
}
.current .customerOltOuterIcon {
    color: $white;
}
.customerOltInnerIcon {
    color: $white;
}
.current .customerOltInnerIcon {
    color: $magenta1;
}
